.login-description {
    padding-top: 3rem;
}

    .login-description h1 {
        text-align: center;
    }

/* .login-form {
    margin-top: 20vh;
} */

.login-input-field {
    border-radius: 5px !important;
    border: 1px solid var(--gray-4, #BDBDBD) !important;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: 428px !important;
    padding: 12px 16px !important;
}

.login-instructions {
    font-size: 1.2rem;
    padding-bottom: 1rem;
}

.login-one-time-password {
    letter-spacing: 0.5rem;
    text-align: center;
}

.login-submit-button {
    border-radius: 10px !important;
    background: var(--blue, #3164F4) !important;
    display: flex !important;
    width: 428px;
    height: 48px;
    padding: 16px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
font-family: sans-serif !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: normal !important;
}


.login-box {
    display: inline-flex !important;
    padding: 22px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 27px !important;
    border-radius: 15px !important;
    background: #FFF;
}

.typography {
    color: var(--black, #000) !important;
    font-family: Poppins;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 58px !important; /* 161.111% */
    letter-spacing: -1.627px;
    margin: 0 !important;
}

.label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    margin: 0 !important;
}

.label>span {
    color: var(--black, #000) !important;
/* Text sm/Medium */
font-family: Inter !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: 20px !important; /* 142.857% */
width: 100%;
}
.login-form-inside {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 11px;
}

.login-form-above-part {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.terms {
    cursor: pointer;
    color: #373737 !important;
    font-family: Inter !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 10px !important; /* 240% */
}

.check-div{
    width: 14px;
    height: 14px;
}
.check-div > input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
    margin: 0 !important;
}

.resend-code {
    color: #061FFF !important;
text-align: center !important;
font-family: Inter !important;
font-size: 10px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 10px !important; /* 240% */
text-decoration: none !important;
}
/* .page-loading-div {
  display: block;
  font-size: 1.5em;
  text-align: center;
  width: 150px;
}

.page-loading-label {
  margin-top: 30px;
}
.animatedLoader {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
} */
#wp-page-loader {
    z-index: 9999;
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(41, 128, 185, 0.86666666666667);
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
}
.spinner {
    /* background: url(images/spinner.gif) no-repeat; */
    background-size: 20px 20px;
    display: inline-block;
    visibility: hidden;
    float: right;
    vertical-align: middle;
    opacity: 0.7;
    width: 20px;
    height: 20px;
    margin: 4px 10px 0;
}
#wp-page-loader .spinner {
    visibility: visible;
    background: none;
    opacity: 1;
    float: none;
}
#wp-page-loader .spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}
#wp-page-loader .cube1,
#wp-page-loader .cube2 {
    background-color: rgba(255, 255, 255, 1);
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out;
}
#wp-page-loader .cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
@keyframes sk-cubemove {
    25% {
        transform: translateX(42px) rotate(-90deg) scale(0.5);
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    }
    50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    }
    50.1% {
        transform: translateX(42px) translateY(42px) rotate(-180deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    }
    75% {
        transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
            scale(0.5);
    }
    100% {
        transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
    }
}

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Inter");
@import url("https://fonts.googleapis.com/css?family=Source Sans Pro");

body {
    margin: 0;
    /* background-color: #ececec !important; */
    /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 29.17%,
    #f0f0f0 75.22%
    ),
    linear-gradient(
      180deg,
      rgba(226, 255, 111, 0.05) 0%,
      rgba(41, 250, 124, 0.05) 20.83%,
      rgba(255, 98, 211, 0.05) 57.29%,
      rgba(255, 141, 141, 0.04) 79.17%,
      rgba(56, 160, 255, 0.04) 100%
      ); */
    background: url("../public/home-background.png"),
        lightgray -35.796px -590.913px / 151.553% 197.483% no-repeat;
}

.fade-in {
    animation: DefaultFadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}

@keyframes DefaultFadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.assessmentSect {
    min-height: 100vh;
    /* height: 100vh; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.isField {
    display: flex;
    flex-wrap: wrap;
    width: 550px;
    border-radius: 15px;
    background: #fff;
    padding: 22px;
    font-family: inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.isField div {
    flex: 1 1 100%;
    margin: 5px;
}

.isField div:nth-child(3),
.isField div:nth-child(2) {
    flex: 1 1 calc(50% - 10px);
}
.isField div.formSubBtn {
    margin-top: 25px;
}
.demographicContainer {
    background-color: #fff;
    max-width: 482px;
    margin: 0 auto;
    padding: 22px 27px;
    border-radius: 15px;
}
.demographicHeader {
    display: flex;
    justify-content: space-between;
}
.demographicHeader h1 {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 58px; /* 241.667% */
}
.demographicHeader span {
    color: #3164f4;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.demographicContainer input {
    max-width: 133px;
    padding: 12px 16px;
    border-radius: 5px;
    border: 1px solid var(--gray-4, #bdbdbd);
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.demographicContainer input::placeholder {
    opacity: 0.5;
}
.demographicContainer p {
    color: #121212;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 115% */
    letter-spacing: -0.012px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.demographicContainer label {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.demographDOBcontainer label {
    margin-bottom: 8px;
}
.demographGender {
    display: flex;
    gap: 30px;
    padding-top: 12px;
}
.demographicBtn {
    padding-top: 35px;
}
.demographicBtn button {
    border-radius: 10px;
    background: var(--blue, #3164f4);
    width: 100%;
    color: #fff;
    border: none;
    height: 48px;
}
/* Chrome, Safari, Edge, Opera */
.demographicContainer input::-webkit-outer-spin-button,
.demographicContainer input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.demographicContainer input[type="number"] {
    -moz-appearance: textfield;
}

@media (max-width: 515px) {
    .assessmentSect .login-form {
        max-width: 350px;
        min-width: 300px;
    }
    .login-box.card {
        width: 100%;
    }
    .typography.card-title.h5 {
        font-size: 26px !important;
        letter-spacing: 1px;
        width: 100% !important;
        line-height: normal !important;
    }
    .login-form-above-part
        input.login-input-field.form-control.form-control-lg {
        width: 100% !important;
    }
    .login-form-inside.col {
        width: 100%;
    }
    .inputBtnContainer {
        width: 100%;
    }
    button.login-submit-button.btn.btn-primary.btn-lg {
        width: 100% !important;
    }
    span.resend-code {
        width: 100% !important;
    }
    .login-code-btns {
        display: flex;
        flex-direction: column;
        gap: 7px;
    }
    button.copy-login-code-to-clipboard.btn.btn-outline-primary {
        padding: 8px;
        margin: 0;
    }
    button.close-login-code-display.ms-3.btn.btn-outline-secondary {
        padding: 10px;
        margin: 0 !important;
    }
    .col-8 {
        flex: 0 0 auto;
        align-items: center;
        width: 66.66666667%;
        display: flex;
    }
    /* .isField {
        width: 100%;
    } */
    .isField {
        width: unset;
        margin: 10px;
    }
}

.login-code-show-button {
    font-size: 0.8rem;
    position: absolute;
    top: 20px;
    right: 20px;
}

@media only screen and (min-width: 501px) {
    .login-code-show-button {
        font-size: 1rem;
        /* top: -70px; */
    }
}
button.login-code-show-button.btn.btn-outline-secondary {
    padding: 16px 24px;
    border-radius: 10px;
    border: 1px solid #3164f4;
    background-color: white;
    color: #3164f4;
    font-weight: 500;
}
button.login-code-show-button.btn.btn-outline-secondary:hover {
    background: var(--blue, #3164f4);
    color: white;
    border: none;
    border: 1px solid var(--blue, #3164f4);
}

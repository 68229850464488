.testerly-logo-text {
  display: block;
  vertical-align: top;
  font-size: 2rem;
  font-family: "Tahoma", sans-serif;
  font-weight: 900;
  letter-spacing: .08rem;
  color: #4279a6;
  width: 15rem;
}

.testerly-logo-image {
  width: 141.928px;
  height: 31.539px;
  flex-shrink: 0;
}

.logo-container {
  display: flex;
  height: 86px;
  padding: 22px 279px;
  justify-content: center;
  align-items: center;
  gap: 341px;
  flex-shrink: 0;
}

@media only screen and (max-width: 500px) {
  .testerly-logo-text {
    font-size: 1.2rem;
    width: 12rem;
  }

  .testerly-logo-image {
    height: 30px;
    margin-left: 10px;
    width: 50px;
  }

  .logo-container {
    min-height: 30px;
  }
}
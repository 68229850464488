.login-code-off-canvas {
    bottom: initial;
    min-height: 320px;
}

.login-code-off-canvas-body {
    text-align: center;
}

.login-code-off-canvas-header {
    display: block;
    padding-bottom: 0;
    text-align: right;
}

.login-code-value {
    font-size: 1.6rem;
}

.copy-login-code-to-clipboard {
    margin-left: 2rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.close-login-code-display {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}